/* Stil für das rotierende Logo */
.rotating-logo {
  height: 40px;
  margin-right: 20px;
  animation: rotate 30s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Höhe der AppBar anpassen */
.MuiAppBar-root {
  height: 80px; /* Höhe des Headers, anpassen nach Bedarf */
}

/* Höhe der Toolbar anpassen */
.MuiToolbar-root {
  height: 100%; /* Höhe auf 100% setzen, um die gesamte Höhe der AppBar auszufüllen */
}

/* Abstand zwischen Titel und Werbung */
.advertisement-container {
  margin-left: 30px; /* Abstand von 30px nach links, um Platz für die Werbung zu schaffen */
}

/* Sicherstellen, dass der Titel ausreichend Abstand zum Bild hat */
.header-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 30px; /* Abstand von 30px nach rechts, um den Titel von anderen Elementen abzusetzen */
}

/* Sicherstellen, dass die Werbung korrekt angezeigt wird */
.advertisement-image {
  height: 80px; /* Höhe der Werbung, 60px weniger als die Höhe des Headers */
  width: auto; /* Breite anpassen, um das Seitenverhältnis zu erhalten */
  margin: 0 30px; /* Abstand links und rechts vom Bild */
  transition: opacity 1s ease-in-out; /* Animation beim Einblenden */
}
