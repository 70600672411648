/* Advertisement.css */

/* Container für die Werbung */
.advertisement-container {
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 30px; /* Abstand zwischen Titel und Werbung */
  opacity: 0;
  transition: opacity 1s ease-in-out; /* Animation für das Einblenden */
}

/* Klasse, die das Bild einblendet */
.advertisement-container.fade-in {
  opacity: 1;
}

/* Stile für das Werbebild */
.advertisement-image {
  cursor: pointer;
  /* Breite und Höhe werden über Inline-Stile im React-Code gesetzt */
  object-fit: contain; /* Beibehaltung des Seitenverhältnisses */
}
